import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import PropTypes from "prop-types"
import React from "react"
import profileImage from "../images/web_prof-small.jpg"

const Header = ({ siteTitle }) => (
  <header className="hero section is-block is-relative is-white is-small">
    <div className="container">
      <div className="columns is-vcentered is-desktop">
        <div className="column">
          <figure className="image" style={{ position: "relative" }}>
            <img
              src={profileImage}
              alt="Seth Corker"
              style={{
                borderRadius: "50%",
                border: "3px dashed rgba(0,0,0,0.1)",
              }}
            />
            <ul
              className="m-0"
              style={{
                listStyle: "none",
                position: "absolute",
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
              }}
            >
              <li
                className="m-0"
                style={{
                  position: "absolute",
                  left: "calc(50% - 1rem - 33%)",
                  top: "90%",
                  transform: "rotate(33deg)",
                }}
              >
                <a
                  title="Seth Corker's LinkedIn Profile"
                  href="https://www.linkedin.com/in/sethcorker/"
                  className="profile-link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={["fab", "linkedin"]} size="2x" />
                </a>
              </li>
              <li
                className="m-0"
                style={{
                  position: "absolute",
                  left: "calc(50% - 1rem)",
                  top: "98%",
                }}
              >
                <a
                  title="Seth Corker's Twitter Profile"
                  href="https://twitter.com/Darth_Knoppix"
                  className="profile-link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={["fab", "twitter"]} size="2x" />
                </a>
              </li>
              <li
                className="m-0"
                style={{
                  position: "absolute",
                  left: "calc(50% - 1rem + 33%)",
                  top: "90%",
                  transform: "rotate(-33deg)",
                }}
              >
                <a
                  title="darthknoppix's DEV Profile"
                  href="https://dev.to/darthknoppix"
                  className="profile-link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={["fab", "dev"]} size="2x" />
                </a>
              </li>
            </ul>
          </figure>
        </div>
        <div className="column is-1"></div>
        <div className="column is-7-desktop has-text-centered-touch">
          <header className="mb-6">
            <h1 className="title has-text-weight-bold is-1 is-size-2-mobile is-spaced has-text-grey-darker">
              Seth Corker
            </h1>
            <p className="subtitle" style={{ lineHeight: 2 }}>
              I'm a <strong>Senior Full Stack Product Engineer</strong> at{" "}
              <a
                href="https://zeroheight.com"
                target="_blank"
                rel="noopener noreferrer"
                className="work-link button is-normal has-background-danger-light has-text-danger is-light has-text-weight-medium is-rounded py-0"
                style={{ verticalAlign: "baseline", height: "1.75rem" }}
              >
                zeroheight
              </a>{" "}
              in London. I help build a solution to document design systems and
              aide adoption of those systems across the business.
            </p>
            <p>
              Previously, a{" "}
              <strong>Senior Full Stack Software Engineer and Tech Lead</strong>{" "}
              at{" "}
              <a
                href="https://www.zego.com/"
                target="_blank"
                rel="noopener noreferrer"
                className="work-link button is-normal is-primary is-light has-text-weight-medium is-rounded py-0"
                style={{ verticalAlign: "baseline", height: "1.75rem" }}
              >
                Zego
              </a>{" "}
              leading the technical direction with micro frontend architecture,
              mentoring engineers and organising the frontend guild. Worked with
              Python - specifically Django and FastAPI. Maintained GrapgQL APIs
              with clients using Relay. Experienced in{" "}
              <a
                href="https://blog.sethcorker.com/efficiently-rendering-lists-in-react/"
                rel="noopener noreferrer"
                title="Open article about rendering lists in React"
                className="button is-normal is-info is-light has-text-weight-medium is-rounded py-0"
                style={{ verticalAlign: "baseline", height: "1.75rem" }}
              >
                React
              </a>{" "}
              +{" "}
              <a
                href="https://blog.sethcorker.com/harnessing-the-page-visibility-api-with-react/"
                rel="noopener"
                title="Open article about using the page visibility API in React"
                className="button is-warning is-light has-text-weight-medium is-rounded py-0"
                style={{ verticalAlign: "baseline", height: "1.75rem" }}
              >
                JavaScript
              </a>{" "}
              and CSS.
            </p>
            <i>
              Even longer ago, a professional <b>C# .NET</b> developer creating
              systems on <b>ASP.NET MVC</b> in New Zealand.
            </i>
            <hr />
            <p className="subtitle">
              I deliver productive GraphQL APIs from Python Django that power
              rich user experiences and build consumer facing onboarding
              experiences with Next.js.
              <br />
              My passions are learning new skills, picking apart technologies
              and crafting cool products.
            </p>
          </header>
        </div>
      </div>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"

import { library } from "@fortawesome/fontawesome-svg-core"
import { fab } from "@fortawesome/free-brands-svg-icons"
import { fas } from "@fortawesome/free-solid-svg-icons"

import Header from "./header"

library.add(fab)
library.add(fas)

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={(data) => (
      <div className="content">
        <Header siteTitle={data.site.siteMetadata.title} />
        <main className="main-container">{children}</main>
        <footer class="footer">
          <div class="content has-text-centered">
            <p>
              Crafted with
              <strong>
                {" "}
                <span role="img" aria-label="love heart emoji">
                  ❤️
                </span>{" "}
                and duct tape
              </strong>{" "}
              by <a href="https://twitter.com/Darth_Knoppix">Seth Corker</a>.
            </p>
          </div>
          <div className="columns is-centered">
            <div className="column is-1 is-flex is-flex-direction-column is-justify-content-center">
              <a href="https://www.netlify.com">
                <img
                  src="https://www.netlify.com/img/global/badges/netlify-color-bg.svg"
                  alt="Deploys by Netlify"
                />
              </a>
            </div>
            <div className="column is-1 is-flex is-flex-direction-column is-justify-content-center">
              <a href="https://bulma.io">
                <img
                  src="https://bulma.io/images/made-with-bulma.png"
                  alt="Made with Bulma"
                  width="128"
                  height="24"
                />
              </a>
            </div>
          </div>
        </footer>
      </div>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
